const toastr = require("toastr");
var buttonClicked = false;

const Asaas = function(){
  var priv = {};
  var pub = {};

  priv.disable_enable = function(name, enable) {
	  if (enable) {
		  $('#' + name).show();
		  var selectField = $('#' + name + ' select');
		  selectField.prop( "disabled", false );
		  //selectField.prop("selectedIndex", 0);
		  //selectField.focus().select();
	  } else {
		  var selectField = $('#' + name + ' select');
		  //selectField.prop("selectedIndex", 0);
		  selectField.prop("disabled", true );
		  $('#' + name).hide();
	  }
  }

  priv.switch_payment_method = function(e){
    priv.payment_method = e.currentTarget.value;

    if (e.currentTarget.value == 'credit_card'){
      $('#credit_card_row').show()
	  priv.disable_enable('boleto_installment', false);
	  priv.disable_enable('other_installment', false);
	  priv.disable_enable('credit_card_installment', true);
    } else {
      $('#credit_card_row').hide()
	  priv.disable_enable('credit_card_installment', false);
	  if (e.currentTarget.value == 'boleto_pix') {
	  	priv.disable_enable('boleto_installment', false);
  	  	priv.disable_enable('other_installment', true);
	  } else {
	  	priv.disable_enable('other_installment', false);
		priv.disable_enable('boleto_installment', true);
	  }
    }
  }


  priv.validateSubmit = function(e){
    e.preventDefault();
    e.stopPropagation();

	if (buttonClicked) {
		return false;
	}
	window.showToast([['sucess', 'Aguarde processando']]);
	buttonClicked = true;

  	if ($('#transaction_person_attributes_name').val().trim().indexOf(" ") < 0) {
  		toastr.error('Campo nome, deve conter nome e sobrenome!.', 'Erro!');
		buttonClicked = false;
  		return false;
  	}

    // $('input#transaction_sender_hash').val(PagSeguroDirectPayment.getSenderHash());
    $('form').unbind('submit').trigger('submit');

    return false
  }

  pub.init = function(){
    $('form').on('submit', priv.validateSubmit);
    $('#transaction_card_number').mask("0000 0000 0000 0000");
    $('#transaction_card_expiration').mask("00/0000");
    $('#transaction_person_attributes_birth_at').mask("00/00/0000");
    $('#transaction_payment_method').on('change', priv.switch_payment_method).trigger('change');
	$('#transaction_card_expiration').blur(function() {
		var exp = $('#transaction_card_expiration').val();
		if (exp.length < 7) {
			$('#transaction_card_expiration').val(exp.replace("/","/20"));
		}
	});

	$('#btn_help')[0].href = $('#btn_help')[0].href.replace('http://help/?', atob("aHR0cHM6Ly93YS5tZS81NTU0OTE0Njg4MTU/dGV4dD0="));

  }

  return pub;
}()

export default Asaas;
