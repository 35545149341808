// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start()
require("turbolinks").start()
require("@rails/activestorage").start()
require("channels")

var jQuery = require("jquery");
global.$ = global.jQuery = jQuery;
window.$ = window.jQuery = jQuery;

require('bootstrap');
require('admin-lte');
require('select2');

import './stylesheet.js';

var cocoon = require('./cocoon.js');
cocoon.default(jQuery);

var utils = require('./utils.js');
utils.default(jQuery);

window.poloni = { utils: {} }
window.poloni.utils.select2_ajax = require('./utils/select2_ajax.js').default;
window.poloni.asaas = require('./asaas/checkout').default;

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%#= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

require("trix")
require("@rails/actiontext")
